<template>
  <div>
    <spinner v-if="loading" />
    <a-row>
      <a-col class="height100 flex-1">
        <a-tabs
          :key="$route.fullPath"
          v-model="tabPane"
          class="main-tabs contacts-form-content"
          type="card"
          @change="tabPaneChangeHandler"
        >
          <a-tab-pane key="content">
            <span slot="tab">
              <a-icon type="project" />
              {{ $route.meta.title[$i18n.locale] }}
            </span>

            <!-- lang tabs -->
            <a-row align="middle" class="flex-baseline sm-mb">
              <a-col :span="3" class="form_title"> {{ $t("Lang") }}: </a-col>
              <a-col :span="12">
                <!-- lang translit tabs -->
                <a-row align="middle" class="flex-baseline flex-wrap">
                  <lang-tabs v-model="langTab" class="mb-1x" />
                  <a-button
                    v-if="langTab === 'oz'"
                    @click.stop="translitAction"
                  >
                    Перевести копию на
                    {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
                  </a-button>
                </a-row>
              </a-col>
            </a-row>

            <!-- address -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Adress") }}: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['address_' + getKey]"
                  :placeholder="$t('EnterAdress')"
                />
              </a-col>
            </a-row>

            <!-- phone helpline -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("FormPhoneHelp") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['phone_' + getKey]"
                  :placeholder="$t('FormPhoneHelp')"
                />
                <!-- <a-input
                  v-model="form.phone"
                  v-mask="'+### (##) ###-##-##'"
                  :placeholder="$t('FormPhoneInfoServicePlace')"
                  @keypress="isNumber"
                />
                -->
              </a-col>
            </a-row>

            <!-- contact phone -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("FormPhoneContact") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['contact_phone_' + getKey]"
                  :placeholder="$t('FormPhoneContact')"
                />
              </a-col>
            </a-row>

            <!-- fax number -->
            <!--
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Faks") }}: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.fax"
                  v-mask="'+### (##) ###-##-##'"
                  :placeholder="$t('EnterFaks')"
                />
              </a-col>
            </a-row>
            -->

            <!-- сhancellery number -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("Chancery") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['office_phone_1_' + getKey]"
                  class="mb-1"
                  :placeholder="$t('EnterChanceryNumb')"
                />
                <!--<a-input
                  v-model="form.office_phone_1"
                  v-mask="'+### (##) ###-##-##'"
                  :placeholder="$t('EnterChanceryNumb')"
                />-->
                <a-input
                  v-model="form['office_phone_2_' + getKey]"
                  :placeholder="$t('EnterChanceryNumb')"
                />
                <!--<a-input
                  v-model="form.office_phone_2"
                  v-mask="'+### (##) ###-##-##'"
                  class="mt-1"
                  :placeholder="$t('EnterChanceryNumb')"
                />-->
              </a-col>
            </a-row>

            <!-- phone information service -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("FormPhoneInfoService") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['information_service_phone_' + getKey]"
                  :placeholder="$t('FormPhoneInfoServicePlace')"
                />
                <!--<a-input
                  v-model="form.information_service_phone"
                  v-mask="'+### (##) ###-##-##'"
                  :placeholder="$t('FormPhoneHelp')"
                  @keypress="isNumber"
                />-->
              </a-col>
            </a-row>

            <!-- control department number -->
            <!-- <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Отдел контроля : </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.control_department_phone_1"
                  :placeholder="$t('FormDepartment')"
                />
                <a-input
                  v-model="form.control_department_phone_2"
                  class="mt-1"
                  :placeholder="$t('FormDepartment')"
                />
              </a-col>
            </a-row>
            -->

            <!-- email -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Email") }} 1: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.email_1"
                  :placeholder="$t('EnterEmail')"
                  @change="form.email_1 = $_toValidEmail($event)"
                />
              </a-col>
            </a-row>

            <!-- email2 -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Email") }} 2: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.email_2"
                  :placeholder="$t('EnterEmail')"
                  @change="form.email_2 = $_toValidEmail($event)"
                />
              </a-col>
            </a-row>

            <!-- bus -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Bus") }}: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input v-model="form.bus" :placeholder="$t('EnterBuses')" />
              </a-col>
            </a-row>

            <!-- minibus -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("MiniBus") }}: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.mini_bus"
                  :placeholder="$t('EnterMinibuses')"
                />
              </a-col>
            </a-row>

            <!-- subway -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> {{ $t("Metro") }}: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['subway_' + getKey]"
                  :placeholder="$t('EnterMetro')"
                />
              </a-col>
            </a-row>

            <!-- reference point -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("ReferencePoint") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form['reference_point_' + getKey]"
                  :placeholder="$t('EnterLandmark')"
                />
              </a-col>
            </a-row>

            <!-- telegram link -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Telegram: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.telegram_link"
                  placeholder="Вставьте ссылку"
                />
              </a-col>
            </a-row>

            <!-- instagram link -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Instagram: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.instagram_link"
                  placeholder="Вставьте ссылку"
                />
              </a-col>
            </a-row>

            <!-- twitter link -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Twitter: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.twitter_link"
                  placeholder="Вставьте ссылку"
                />
              </a-col>
            </a-row>

            <!-- facebook link -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Facebook: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.facebook_link"
                  placeholder="Вставьте ссылку"
                />
              </a-col>
            </a-row>

            <!-- linkedin link -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title"> Linkedin: </a-col>
              <a-col :span="12" class="sm-mb">
                <a-input
                  v-model="form.linkedin_link"
                  placeholder="Вставьте ссылку"
                />
              </a-col>
            </a-row>

            <!-- photo -->
            <!-- <a-row>
              <a-col :span="3" class="form_title">
                {{ $t("TablePhotos") }}:
              </a-col>
              <a-col :span="12" class="sm-mb">
                <input
                  type="file"
                  accept="image/jpg, image/jpeg, image/png, image/svg"
                  @change="handleFileUpload"
                />
                <a-upload name="file" :multiple="true" v-model="form.photo">
                  <a-button>
                    <a-icon type="upload" /> Click to Upload
                  </a-button>
                </a-upload>
                <div class="contact-image">
                  <img :src="image_url" />
                </div>
              </a-col>
            </a-row> -->

            <!-- work times -->
            <a-row align="middle" class="flex-baseline">
              <a-col :span="3" class="form_title">
                {{ $t("WorkingTime") }}:
              </a-col>
              <a-col :span="8" class="sm-mb">
                <!-- Week days -->

                <a-row align="middle" class="mb-2" type="flex">
                  <!-- <span class="mr-1 gray-text short-day-names text-center"
                    >{{ shortDayName[1][$i18n.locale] }}-
                    {{ shortDayName[5][$i18n.locale] }}</span
                  > -->

                  <a-input
                    v-model="form['work_week_' + getKey]"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <!-- <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[2][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.tuesday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[3][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.wednesday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[4][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.thursday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[5][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.friday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[6][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.saturday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">{{
                    shortDayName[7][$i18n.locale]
                  }}</span>

                  <a-input
                    v-model="form.sunday"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row> -->
                <a-row align="middle" class="mb-2" type="flex">
                  <span class="mr-1 gray-text short-day-names text-center">
                    {{ shortDayName[8][$i18n.locale] }}
                  </span>

                  <a-input
                    v-model="form['lunch_' + getKey]"
                    class="time-picker-input__style"
                    placeholder="Введите время"
                    @keypress="isTimeInput"
                  />
                </a-row>

                <!--            <a-row align="middle" class="mb-2" type="flex" v-for="(day, index) in 6">-->
                <!--              <span-->
                <!--                class="mr-1 gray-text short-day-names text-center">{{ shortDayName[index + 1][$i18n.locale] }}</span>-->
                <!--              <a-time-picker :defaultValue="moment('09:00', 'HH:mm')"-->
                <!--                             @change="onTimePick('fromTime', index, arguments)"-->
                <!--                             class="time-picker-input__style"-->
                <!--                             format="HH:mm"/>-->
                <!--              <span class="mx-1"></span>-->
                <!--              <a-time-picker :defaultValue="moment('18:00', 'HH:mm')"-->
                <!--                             @change="onTimePick('toTime', index, arguments)"-->
                <!--                             class="time-picker-input__style"-->
                <!--                             format="HH:mm"/>-->
                <!--            </a-row>-->
              </a-col>
            </a-row>
          </a-tab-pane>

          <!--
          <a-tab-pane key="press_service">
            <span slot="tab">
              <a-icon type="project" />
              {{ title[$i18n.locale] }}
            </span>

            <a-row align="middle" class="flex-baseline sm-mb">
              <a-col :span="3" class="form_title"> Telegram: </a-col>
              <a-col :span="12">
                <a-input
                  v-model="apeal.telegram_link"
                  placeholder="Введите ориентир"
                />
              </a-col>
            </a-row>

            <a-row align="middle" class="flex-baseline sm-mb">
              <a-col :span="3" class="form_title"> {{ $t("Email") }}: </a-col>
              <a-col :span="12">
                <a-input
                  v-model="apeal.email"
                  :placeholder="$t('EnterEmail')"
                  @change="apeal.email = $_toValidEmail($event)"
                />
              </a-col>
            </a-row>

            <a-row class="" type="flex">
              <a-col :span="3" class="form_title" />
              <a-col>
                <a-button
                  :loading="pending"
                  type="primary"
                  @click="submitapeal"
                >
                  <a-icon type="check" />{{ textButton }}
                </a-button>
              </a-col>
            </a-row>
          </a-tab-pane>

          <a-tab-pane key="ministry_contact">
            <span slot="tab">
              <a-icon type="project" />
              {{ titleMinistry[$i18n.locale] }}
            </span>
            <a-row type="flex" align="middle" style="justify-content: flex-end">
              <a-button
                type="primary"
                class="sm-mb"
                @click="openCreateModal()"
              >
                <a-icon type="plus" />{{ $t("Add") }}
              </a-button>
            </a-row>
            <a-table
              :columns="columns"
              :data-source="minContactList"
              :loading="loading"
              :pagination="{ defaultPageSize: 5 }"
            >
              <template slot="con_title" slot-scope="item">
                <div>
                  <div
                    class="td-post-item"
                    tag="span"
                    @click="openCreateModal()"
                  >
                    {{ item.title || "-" }}
                  </div>
                </div>
              </template>
              <template slot="url" slot-scope="item">
                <div>
                  {{ item.url }}
                </div>
              </template>
              <template slot="operation" slot-scope="item">
                <a-popconfirm
                  cancel-text="Нет"
                  ok-text="Да"
                  title="Вы действительно хотите удалить?"
                >
                  <a-icon
                    class="action-btns"
                    style="margin: 0 10px"
                    type="delete"
                    @confirm="removeUser(item)"
                  />
                </a-popconfirm>
              </template>
            </a-table>
          </a-tab-pane>
          -->
        </a-tabs>

        <a-row
          v-if="tabPane === 'content'"
          class="form-buttons__action"
          type="flex"
        >
          <a-col :span="3" class="form_title" />
          <a-col>
            <a-button :disabled="pending" @click="$router.go(-1)">
              <a-icon type="close" />{{ $t("Cancel") }}
            </a-button>
            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="submit"
            >
              <a-icon type="check" />{{ textButton }}
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-modal
      :visible="modalVisible"
      title="Добавить"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <!-- lang translit tabs -->
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Название:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="ministry_form['title_' + getKey]"
            placeholder="Название"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Ссылка:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input v-model="ministry_form.url" placeholder="ссылка" />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
        <a-button type="primary" @click="saveOrgType"> Сохранить </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
// import { mapActions } from "vuex"
export default {
  components: {},
  data() {
    return {
      modalVisible: false,
      langTab: $langPrefix,
      contacts: null,
      loading: false,
      tabPane: "content",
      pending: false,
      apeal: {
        email: "",
        telegram_link: ""
      },
      columns: [
        {
          title: "Название",
          scopedSlots: { customRender: "con_title" },
          key: "con_title",
          width: "50%"
        },
        {
          title: "Ссылка",
          scopedSlots: { customRender: "url" },
          key: "url"
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right"
        }
      ],
      ministry_form: {
        title_ru: "",
        title_en: "",
        title_uz: "",
        title_oz: "",
        title_qr: "",
        url: ""
      },
      minContactList: [],
      form: {
        address_ru: "",
        address_oz: "",
        address_en: "",
        address_uz: "",
        address_qr: "",
        subway_ru: "",
        subway_oz: "",
        subway_en: "",
        subway_uz: "",
        subway_qr: "",
        reference_point_ru: "",
        reference_point_oz: "",
        reference_point_en: "",
        reference_point_uz: "",
        reference_point_qr: "",
        phone_ru: "",
        phone_oz: "",
        phone_en: "",
        phone_uz: "",
        phone_qr: "",
        contact_phone_ru: "",
        contact_phone_oz: "",
        contact_phone_en: "",
        contact_phone_uz: "",
        contact_phone_qr: "",
        // phone_help: "",
        work_week: "",
        information_service_phone_ru: "",
        information_service_phone_oz: "",
        information_service_phone_en: "",
        information_service_phone_uz: "",
        information_service_phone_qr: "",
        control_department_phone_1: "",
        control_department_phone_2: "",
        office_phone_1_ru: "",
        office_phone_1_oz: "",
        office_phone_1_en: "",
        office_phone_1_uz: "",
        office_phone_1_qr: "",
        office_phone_2_ru: "",
        office_phone_2_oz: "",
        office_phone_2_en: "",
        office_phone_2_uz: "",
        office_phone_2_qr: "",
        fax: "",
        email_1: "",
        email_2: "",
        bus: "",
        mini_bus: "",
        // photo: null,
        facebook_link: "",
        instagram_link: "",
        telegram_link: "",
        twitter_link: "",
        linkedin_link: "",

        // lat_lng: {
        //   lat: 41.304656,
        //   lng: 69.264127
        // },
        // monday: "",
        // tuesday: "",
        // wednesday: "",
        // thursday: "",
        // friday: "",
        // saturday: "",
        // sunday: "",
        lunch: ""
      },
      title: {
        uz: "Onlayn matbuot xizmati",
        oz: "Онлайн матбуот хизмати",
        ru: "Сервис пресс-служба",
        en: "Online press service"
      },
      titleMinistry: {
        uz: "Хужжат холати",
        oz: "Хужжат холати",
        ru: "Статус писем",
        en: "Статус писем"
      },
      weekDays: {
        // monday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // tuesday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // wednesday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // thursday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // friday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // saturday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // sunday: {
        //   fromTime: "",
        //   toTime: ""
        // },
        // lunch: {
        //   fromTime: "",
        //   toTime: ""
        // }
      }
      // image_url: ""
    }
  },
  computed: {
    dayNamesList() {
      return Object.keys(this.weekDays)
    },
    getKey() {
      return this.langTab
    },
    textButton() {
      return this.contacts ? "Сохранить" : "Создать"
    }
  },
  async created() {
    // this.fetchContacts()
    this.contacts = await this.$store.dispatch("contacts/getContact")
    // console.log(this.contacts)
    if (this.contacts && Object.keys(this.contacts).length) {
      this.form = this.contacts
      // this.image_url = this.form && this.form.photo
      // this.form.photo = null
    }
    /*
    let res = await this.$api.get("/admin/contact/appeal/update/")
    this.apeal = res && res.data
    let minContact = await this.$api.get("/admin/contact/ministry/list/")
    // console.log(minContact)
    if (minContact) {
      this.minContactList = minContact && minContact.data
    }*/
    // console.log(this.form)
  },
  mounted() {},
  methods: {
    async submitapeal() {
      try {
        let res = await this.$api.patch(
          "/admin/contact/appeal/update/",
          this.apeal
        )
        console.log(res)
      } catch (error) {
        return error
      }
    },
    // ...mapActions(["fetchContacts"]),
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.address[from]) {
          this.form.address[to] = this[methodName](this.form.address[from])
        }
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    // handleFileUpload(e) {
    //   this.form.photo = this.$refs.file.files[0]
    //   console.log(e.target.files[0])
    //   this.image_url = URL.createObjectURL(e.target.files[0])
    //   this.form.photo = e.target.files[0]
    // },
    tabPaneChangeHandler(arg) {
      this.tabPane = arg
    },
    async submit() {
      this.pending = true
      // console.log(this.form)
      let f = new FormData()
      Object.keys(this.form).forEach((k) => {
        if (this.form[k] === null) {
          this.form[k] = ""
        }
        f.append(k, this.form[k])
        /*k !== "photo" && f.append(k, this.form[k])
        k === "photo" &&
          this.form[k] &&
          f.append(k, this.form[k], this.form[k].name)*/
      })

      /*for (let pair of f.entries()) {
        console.log(pair[0] + " " + pair[1])
      }*/

      try {
        let res = await this.$api({
          method: this.contacts ? "patch" : "get",
          url: this.contacts
            ? "/admin/contact/update/"
            : "/admin/contact/update/",
          // data: { ...this.form }
          data: f
        })
        if (res.status === 200) {
          this.$router.go(0)
        }
      } catch (e) {
        console.log(e)
      }

      // try {
      //   const r = await this.$api.post("/contact/create", {
      //     address: JSON.stringify(this.form.address),
      //     bus: JSON.stringify(this.form.bus),
      //     minibus: JSON.stringify(this.form.minibus),
      //     subway: JSON.stringify(this.form.subway),
      //     reference_point: JSON.stringify(this.form.reference_point)
      //   })
      //   if (r) {
      //     this.$message.success("Данные сохранены")
      //   }
      // } catch (e) {
      //   console.log(e)
      //   this.pending = false
      // }

      this.pending = false
    },
    openCreateModal() {
      this.modalVisible = true
      this.ministry_form = {
        title_ru: "",
        title_en: "",
        title_uz: "",
        title_oz: "",
        title_qr: "",
        url: ""
      }
    },
    saveOrgType() {
      try {
        console.log("saveOrgType")
      } catch (error) {
        return error
      }
    }
  }
}
</script>

<style class="">
.main-tabs.contacts-form-content {
  height: unset;
}
input[type="file"] {
  margin-bottom: 15px;
}
.contact-image {
  height: 200px;
  overflow: hidden;
}
.contact-image > img {
  height: 100%;
}
</style>
